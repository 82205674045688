<template>
  <div class="sm:c-col-4 md:c-col-8 lg:c-col-6">
    <div>
      <p-form
        @submit="onSubmit"
        v-slot="{ invalid }"
      >
        <div class="c-text-subtitle ui-mb-4">
          Faça login com sua conta Estratégia
        </div>

        <!-- Email -->
        <div class="c-row ui-mb-4">
          <validation-provider
            class="c-col"
            rules="required|email"
            v-slot="{ errors }"
            name="Email"
          >
            <p-input
              v-model="user.email"
              data-cy="loginField"
              label="Email*"
              name="loginField"
              :error-message="errors[0]"
              type="email"
            />
          </validation-provider>
        </div>

        <!-- Senha / Esqueci minha senha -->
        <div class="c-row ui-mb-8">
          <!-- Senha -->
          <validation-provider
            class="c-col-2 sm:c-col"
            rules="required|min:5"
            v-slot="{ errors }"
            :custom-messages="{
              required: 'Senha é obrigatória',
              min: 'A senha é muito curta'
            }"
          >
            <input-password
              v-model="user.password"
              data-cy="passwordField"
              label="Senha*"
              name="passwordField"
              :error-message="errors[0]"
              :disable-dafault-state="true"
            />
          </validation-provider>

          <!-- Esqueci minha senha -->
          <div class="c-col-2 sm:c-col">
            <div class="ui-h-input ui-flex ui-items-center">
              <router-link
                :to="{ name: 'esqueci-senha', query: { ...$route.query, email: user.email } }"
                data-cy="forgotPasswordLink"
                class="c-text-hyperlink c-text-gray"
              >
                Esqueci minha senha
              </router-link>
            </div>
          </div>
        </div>

        <!-- Codigo de verificação -->
        <div
          class="ui-mb-10"
          v-if="isMFARequired"
        >
          <p-input
            v-model="user.mfaCode"
            mask="000000"
            :maxlength="6"
            type="text"
            label="Código de verificação"
            input-class="mfa-code-input"
            class="ui-mb-2"
          />
          <div class="q-pt-xs">
            <c-button
              data-cy="registerButton"
              size="sm"
              type="submit"
              color="primary"
              :disabled="mfaRetryCounter > 0"
              @click="() => requestAnotherMFA()"
            >
              {{ MFAButtonLabel }}
              <icon
                name="next"
                class="c-icon--right"
              />
            </c-button>
          </div>
        </div>

        <template v-if="showSocialLogin">
          <!-- Social Login Título -->
          <div class="c-row ui-mb-2">
            <div class="c-col c-d-flex c-center">
              <span class="c-text-b2 c-text-gray-300">Ou continue com:</span>
            </div>
          </div>

          <!-- Social Login Botões -->
          <div class="c-row ui-mb-8">
            <div class="c-col">
              <separator />
            </div>

            <div class="c-d-flex">
              <social-login-button provider="facebook" />
              <social-login-button provider="google" />
              <social-login-button provider="apple" />
            </div>

            <div class="c-col">
              <separator />
            </div>
          </div>
        </template>

        <div class="c-row">
          <!-- Botão Continuar -->
          <div class="c-col sm:c-col-1/2">
            <vue-recaptcha
              v-if="showRecaptcha"
              ref="recaptcha"
              :sitekey="$env.VUE_APP_GOOGLE_RECAPTCHA_SITE_KEY"
              :load-recaptcha-script="true"
              @verify="response => setRecaptchaToken(response)"
              @expired="() => clearRecaptchaToken()"
              @error="() => clearRecaptchaToken()"
            />
            <c-button
              v-else
              :disabled="invalid"
              data-cy="registerButton"
              size="lg"
              type="submit"
              class="ui-w-full"
              :loading="isFormSubmitting"
            >
              Continuar
              <icon
                name="next"
                class="c-icon--right"
              />
            </c-button>
          </div>
        </div>
      </p-form>

      <template v-if="showSignup">
        <separator />

        <div class="c-row">
          <div class="c-col">
            <div class="c-text-gray">
              <span class="c-text-b2">Ainda não tem conta? </span>
              <router-link
                :to="{ name: 'cadastro', query: { ...$route.query } }"
                data-cy="cadastroLink"
                class="c-text-hyperlink"
              >
                Faça o cadastro
              </router-link>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { CButton, Icon } from '@estrategiahq/coruja-web-ui';
import InputPassword from '@/components/input-password';
import PInput from '@/components/p-input';
import PForm from '@/components/p-form';
import Separator from '@/components/separator';
import SocialLoginButton from '@/components/p-social-login-button';
import GenericModal from '@/modals/generic';
// import { notAuthenticatedError, notAuthorizedError, requestCanceledError, unconfirmedEmailError, userNotFoundError } from '@/utils/errors';
import { requestCanceledError } from '@/utils/errors';
import { ValidationProvider } from 'vee-validate';
import { LOGOUT_REASON_KEY, LOGOUT_REASON_MESSAGES, ACCOUNTS_OAUTH_CALLBACK_URL } from '@/consts';
import VueRecaptcha from 'vue-recaptcha';
import { extractUtmFromCookies } from '@/utils/utm';
import { getQueryParam } from '@/utils/url';

export default {
  components: {
    SocialLoginButton,
    PInput,
    CButton,
    Icon,
    InputPassword,
    Separator,
    ValidationProvider,
    PForm,
    VueRecaptcha,
  },
  data: () => {
    return {
      user: {
        email: '',
        password: '',
        mfaCode: '',
      },
      oauth_state: '',
      isFormSubmitting: false,
      isRecaptchaRequired: false,
      recaptchaToken: '',
      showSocialLogin: false,
      showSignup: false,
      isMFARequired: false,
      MFAButtonLabel: 'Enviar outro código',
      mfaRetryCounter: 10,

    };
  },
  watch: {
    mfaRetryCounter: {
      handler (value) {
        if (value > 0) {
          setTimeout(() => {
            this.mfaRetryCounter--;
            this.updateMFAButtonLabel();
          }, 1000);
        }
      },
      immediate: true,
    },
  },
  mounted () {
    const email = this.$route.query.email;
    if (email) {
      this.user.email = email.trim();
    }

    this.oauth_state = getQueryParam('state');
    const hideSocial = this.getBoolParam('hide-social');
    if (!hideSocial) {
      this.$socialLogin.someActive().then((someActive) => {
        this.showSocialLogin = someActive;
      });
    }

    const hideSignup = this.getBoolParam('hide-signup');
    if (!hideSignup) {
      this.showSignup = true;
    }

    const logoutReason = sessionStorage.getItem(LOGOUT_REASON_KEY);
    if (logoutReason) {
      this.showModalLogoutReason(logoutReason);
    }
    sessionStorage.removeItem(LOGOUT_REASON_KEY);
  },
  computed: {
    showRecaptcha() {
      return this.isRecaptchaRequired && (!this.recaptchaToken || this.recaptchaToken === '');
    },
  },
  methods: {
    updateMFAButtonLabel () {
      if (this.mfaRetryCounter > 0) {
        this.MFAButtonLabel = `Enviar outro código em ${this.mfaRetryCounter} segundos`;
      } else {
        this.MFAButtonLabel = 'Enviar outro código';
      }
    },
    getBoolParam(name) {
      try {
        const val = JSON.parse(this.$route.query[name]);
        return !!val;
      } catch (_) {
        return false;
      }
    },
    async persistUtmIfExists() {
      try {
        const utm = extractUtmFromCookies();
        if (utm) {
          this.$services.bff.setUtm(utm);
        }
      } catch (_) {
        // possível erro não deve ser mostrado ao usuário
      }
    },
    async onSubmit() {
      this.isFormSubmitting = true;
      try {
        const logindata = await this.$services.accounts.login(this.user.email, this.user.password, this.recaptchaToken, this.user.mfaCode, this.oauth_state);
        this.loginSuccessHandler(logindata);
      } catch (e) {
        if (e === requestCanceledError) {
          return;
        }
        await this.handleLoginErrors(e?.response?.data?.error?.tag);
      } finally {
        this.isFormSubmitting = false;
      }
    },
    showModalLogoutReason(logoutReason) {
      const message = LOGOUT_REASON_MESSAGES[logoutReason];
      if (!message) return;

      this.$modal.show({
        component: GenericModal,
        overlayOpacity: 0.5,
        props: {
          title: '',
          subTitle: message,
          type: 'error',
          confirmButton: {
            label: 'Fechar',
            onClick: () => this.$modal.hide(),
          },
        },
      });
    },
    loginSuccessHandler(logindata) {
      if (this.$route.query.parceiro) {
        localStorage.setItem('partner@estrategia', this.$route.query.parceiro);
      }
      // Limpa Estado
      this.isMFARequired = false;

      // Redireciona para a página inicial ou callback do oauth
      if (logindata.oauth_callback_url) {
        localStorage.setItem(ACCOUNTS_OAUTH_CALLBACK_URL, logindata.oauth_callback_url);
        if (localStorage.getItem(ACCOUNTS_OAUTH_CALLBACK_URL)) {
          this.$router.push({ name: 'oauth-callback-redirect' });
        }
      } else {
        this.$services.events.notifyManualLogin();
        this.persistUtmIfExists();
        this.$router.push({
          name: 'dados-complementares',
          query: this.$route.query,
        });
        localStorage.removeItem('last_login_failed');
      }
    },
    async handleLoginErrors (accountsError) {
      this.isRecaptchaRequired = false;
      localStorage.setItem('last_login_failed', true);
      switch (accountsError) {
      case 'LEAKED_PASSWORD':
        this.$router.push({ name: 'esqueci-senha', query: { ...this.$route.query, email: this.user.email } });
        this.$toast.show({
          type: 'error',
          text: 'Sua senha foi exposta em um vazamento de dados e sua conta foi bloqueada. Para desbloquear, redefina sua senha.',
          timeout: 10000,
        });
        break;
      case 'REQUIRE_PASSWORD_CHANGE':
        this.$router.push({ name: 'esqueci-senha', query: { ...this.$route.query, email: this.user.email } });
        this.$toast.show({
          type: 'error',
          text: 'Sua senha expirou. Redefina uma nova senha.',
          timeout: 5000,
        });
        break;
      case 'REQUIRE_MFA_CODE':
        this.isMFARequired = true;
        this.mfaRetryCounter = 30;
        this.$toast.show({
          type: 'success',
          text: 'Código de verificação enviado para o seu email.',
          timeout: 5000,
        });
        break;
      case 'INVALID_CREDENTIALS':
      case 'AUTH.NOT_AUTHENTICATED':
        this.$toast.show({
          type: 'error',
          text: 'Usuário ou senha inválidos.',
          timeout: 3000,
        });
        break;
      case 'ERROR_CONFIRMING_CAPTCHA':
      case 'INVALID_CAPTCHA':
        this.isRecaptchaRequired = true;
        this.recaptchaToken = '';
        this.$toast.show({
          type: 'error',
          text: 'Captcha inválido ou expirado',
          timeout: 1000,
        });
        break;
      case 'INVALID_MFA_CODE':
        this.$toast.show({
          type: 'error',
          text: 'Código de verificação inválido.',
          timeout: 3000,
        });
        break;
      default:
        this.$toast.show({
          type: 'error',
          text: accountsError || 'undefined',
          timeout: 3000,
        });
        break;
      }
    },
    async requestAnotherMFA () {
      this.user.mfaCode = '';
      try {
        await this.$services.accounts.login(this.user.email, this.user.password, this.recaptchaToken, this.user.mfaCode, this.oauth_state);
      } catch (e) {
        await this.handleLoginErrors(e?.response?.data?.error?.tag);
      }
    },
    setRecaptchaToken (token) {
      this.recaptchaToken = token;
      localStorage.setItem('recaptcha_token', token);
    },
    clearRecaptchaToken () {
      this.recaptchaToken = '';
      localStorage.removeItem('recaptcha_token');
    },
  },
};
</script>

<style>
  .mfa-code-input {
    font-family: ui-monospace, monospace;
    font-size: 20px;
    text-align: center;
    letter-spacing: 5px;
  }
</style>
